<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [
        { value: 'number', text: this.$t('number'), title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'packet', text: this.$t('packetCoefficient') },
        { value: 'sailorFullName', text: this.$t('sailor') },
        { value: 'rank', text: this.$t('rank') },
        { value: 'sum_f1f2', text: this.$t('coming'), align: 'center', total: { global: 'global_form2_sum', page: 'page_form2_sum', align: 'center' }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'page_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({
            name: 'sailor',
            params: { id: item?.sailor?.id },
            query: { ...this.$route.query } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-account-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        },
        {
          id: 2,
          to: (item) => ({
            name: 'debtor-report-packet',
            params: { packet_item: item?.packet },
            query: { ...this.$route.query } }),
          color: 'blue',
          target: '_blank',
          name: 'mdi-information-outline',
          tooltip: 'tooltip.info',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDebtorSailors,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDebtorSailor']),
    async getData (params) {
      this.getListDebtorSailor({ params: { ...params, ...this.$route.params } })
    }

  }
}
</script>
